<template>
  <div v-if="this.orders">
    <hr class="border-gray-300" />
    <div v-for="order in filteredOrders" :key="order.id">
      <div class="p-2 mt-3">
        <div class="flex flex-row items-start">
          <div class="w-full">
            <div class="flex flex-row justify-between">
              <p class="font-bold mb-0 inline">
                Order #{{ isSingleOrder ? formatUuid(order.id) : formatUuid(order.uuid) }}
              </p>
              <p class="mb-0 inline">
                {{ formatCurrency(amountPaid(order), { precision: 2, currency: currency() }) }}
              </p>
            </div>

            <p class="mt-1 text-sm text-gray-500">
              Purchased for
              <a
                :href="`${dashboardUrl}/raffle?id=${isSingleOrder ? order.eventId : order.event.uuid}`"
                class="underline"
              >
                {{ isSingleOrder ? order.eventName : order.event.name }}
              </a>
            </p>
          </div>
        </div>
      </div>
      <hr class="border-gray-300 mt-3" />
    </div>
  </div>
</template>

<script>
import config from '@/config';

export default {
  props: {
    orders: {
      type: Array
    },
    isSingleOrder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dashboardUrl: config.RAFFLEBOX_DASHBOARD_URL
    };
  },
  computed: {
    filteredOrders() {
      return this.orders.filter((order) => {
        return !order.voidedAt && order.ticketPricing;
      });
    }
  },
  methods: {
    amountPaid(order) {
      if (order.ticketPricing) {
        if (this.isSingleOrder) {
          return order.ticketPricing.totalPriceCents / 100;
        } else {
          return order.ticketPricing.totalPrice;
        }
      } else {
        return order.amountCents / 100;
      }
    },
    currency() {
      return this.orders[0].currency?.toUpperCase();
    }
  }
};
</script>
