<template>
  <GenericModal
    ref="exportModal"
    :title="'Export Job Created'"
    :config="modalConfig"
    @modal-opened="onModalOpened"
    @modal-closed="onModalClosed"
  >
    <template #openButton="{ openModal }">
      <BaseButton
        variant="secondary-outline"
        iconFamily="fas"
        icon="download"
        @click.native="downloadReport(openModal)"
      >
        Send for Print
      </BaseButton>
    </template>
    <template #default>
      <Alert v-if="errorMessage" variant="red" icon="exclamation">
        <p>{{ errorMessage }}</p>
      </Alert>
      <Alert v-else variant="green" icon="info">
        <p>
          Your export job has been created successfully. You can monitor the progress and download the file from the
          <a href="/exports" class="text-blue-500">Exports page</a> once it is ready.
        </p>
      </Alert>
    </template>
  </GenericModal>
</template>

<script>
import JobExportService from '@/lib/export-job-service-v2';
import GenericModal from '@/components/modals/GenericModal.vue';
import Alert from '@/components/ui/Alert.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    GenericModal,
    Alert,
    BaseButton
  },
  props: {
    orderGroupId: {
      type: String,
      required: true,
      default: ''
    }
  },

  data() {
    return {
      organizationId: null,
      csvData: null,
      canViewExports: false,
      errorMessage: null,
      modalConfig: {
        modalButtonLabel: 'Send for Print',
        cancelButtonLabel: 'Close'
      }
    };
  },
  mounted() {
    this.organizationId = this.$store.state.organization.id;
  },
  methods: {
    async downloadReport(openModal) {
      try {
        const x = await JobExportService.createExportJob({
          organizationId: this.organizationId,
          reportName: 'mnp-order-printing',
          filters: [{ field: 'orderGroupId', operator: '=', value: this.orderGroupId }]
        });

        openModal();
      } catch (error) {
        this.errorMessage = this.formatErrorMessage(error);
        openModal();
      }
    },
    formatErrorMessage(error) {
      if (error.response && error.response.data && error.response.data.errors) {
        return error.response.data.errors.map((err) => `${err.message}: ${err.path}`).join(', ');
      } else {
        return 'An unexpected error occurred.';
      }
    },
    onModalOpened() {
      return;
    },
    onModalClosed() {
      return;
    }
  }
};
</script>
