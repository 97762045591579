<template>
  <div>
    <div class="flex flex-col">
      <Alert v-if="errorMessage" variant="red" icon="exclamation"> Error: {{ errorMessage }} </Alert>
      <Alert v-if="success" variant="green"> {{ success }} </Alert>
      <div v-if="loading" class="flex justify-center items-center">
        <LoadingSpinner />
      </div>

      <div class="flex flex-col" v-if="!loading">
        <!-- Action Bar -->
        <div class="inline-flex flex-wrap gap-2 mb-10">
          <BaseButton
            v-b-modal="`edit-order-${order.id}`"
            variant="secondary-outline"
            icon="pen-to-square"
            v-if="showEditOrderV2"
          >
            Edit Order
          </BaseButton>
          <EditOrderModalV2 :modalId="order.id" :orderId="order.id" @orderUpdated="updateSuccess" />

          <BaseButton
            @click.native="$router.push(`/tickets?orderId=${orderId}`)"
            variant="secondary-outline"
            iconFamily="fas"
            icon="ticket"
            :disabled="!(canViewTickets && showTickets)"
          >
            View Tickets
          </BaseButton>

          <BaseButton
            v-b-modal="`email-order-${order.id}`"
            variant="secondary-outline"
            iconFamily="fas"
            icon="envelope"
            :disabled="!(canEmailOrder && showTickets)"
          >
            Email Order
          </BaseButton>

          <BaseButton
            @click.native="$router.push(`/tickets/print-mail?orderId=${orderId}`)"
            variant="secondary-outline"
            iconFamily="fas"
            icon="print"
            :disabled="!(canPrintOrder && showTickets)"
          >
            Print Order
          </BaseButton>

          <BaseButton
            @click.native="goToTicketPage()"
            variant="secondary-outline"
            iconFamily="fas"
            icon="ticket"
            :disabled="!(canViewTickets && showTickets)"
          >
            Preview Tickets
          </BaseButton>

          <DownloadFile
            :retrieveData="getDownloadTicketsUrl"
            :filename="`${order.eventName} - Order #${formatUuid(order.id)}.pdf`"
            @error="errorMessage = $event"
            v-if="canDownloadTickets && showTickets"
          >
            <template #default="{ downloadFile }">
              <BaseButton
                @click.native="downloadFile"
                variant="secondary-outline"
                iconFamily="fas"
                icon="download"
                :loading="buttonLoading"
                :disabled="!(canDownloadTickets && showTickets)"
              >
                Download Tickets
              </BaseButton>
            </template>
          </DownloadFile>

          <MNPSingleOrderPrinting v-if="canViewMNPOrderPrinting" :orderGroupId="order.orderGroupId" />

          <ToggleOrderActivationModal
            v-if="canToggleOrderActivation && !isFailedOrder && !disableActivateOrderModal"
            :order="order"
            :groupOrders="groupOrders"
            :orderGroupId="order.orderGroupId"
            @success="updateSuccess"
          />

          <RefundModalV2
            v-if="!isFailedOrder"
            :order="order"
            :groupOrders="groupOrders"
            @success="updateSuccess"
            :disabled="!voidableOrder"
            :reason="voidReason"
          />

          <EmailOrderModal :modalId="orderId" :order="order" />

          <FailOrderGroupModal v-if="isPendingOrder" :orderGroupId="order.orderGroupId" @success="updateSuccess" />
        </div>

        <!-- Main Content -->
        <div class="flex xs:gap-10 md:gap-20 flex-wrap">
          <!-- Order Details -->
          <div class="flex flex-column max-w-2xl w-full order-details">
            <!-- Details Header -->
            <div class="flex flex-column gap-3 pb-1">
              <div class="flex items-start sm:flex-row gap-2 align-center">
                <strong class="text-xl">Details</strong>

                <OrderStatusBadge v-if="order.status" :status="order.status" />
              </div>
              <p class="mb-0">
                <span>Purchased for </span>
                <a :href="`/raffle?id=${order.eventId}`" class="underline">{{ order.eventName }}</a>
              </p>
            </div>

            <!-- Created By -->
            <div class="flex flex-column text-sm font-light mb-2">
              <span v-if="order.createdBy">Created By: {{ order.createdBy.name }}</span>
              <span v-if="order.createdBy">{{ order.createdBy.email }}</span>
            </div>

            <!-- Error Message -->
            <div class="order-info w-full md:w-5/6">
              <Alert v-if="failureError" variant="red" icon="exclamation">
                {{ failureError }}
              </Alert>
              <Alert v-if="order.refund" variant="yellow" icon="exclamation"> Refunded: {{ orderVoidedAt }} </Alert>
            </div>

            <!-- Body -->
            <div
              class="
                grid grid-cols-1
                border-solid border-t border-b border-zinc-400
                py-3
                sm:grid-cols-2
                md:grid-cols-3
                w-full
                gap-x-20 gap-y-7
              "
            >
              <div class="w-full">
                <!-- Payment Details -->
                <p class="text-lg font-bold mb-2">Payment Details</p>

                <div>
                  <p class="mb-1.5">
                    {{
                      formatCurrency(order.amountPaidCents / 100, {
                        precision: 2,
                        currency: currency
                      })
                    }}
                  </p>
                  <p v-if="order.drawNum" class="mb-1.5">
                    <strong>Draw #{{ order.drawNum }}</strong>
                  </p>
                  <div class="mb-1.5">
                    <p class="font-semibold">Date:</p>
                    <p id="order-datetime">{{ order.createdAt ? formatDate(order.createdAt) : '' }}</p>
                    <b-tooltip target="order-datetime" triggers="hover" v-if="order.createdAt">
                      {{
                        formatDateTime(parseISO(order.createdAt), {
                          format: 'dateTime',
                          timeZone: sessionUserTimeZone,
                          displayTimeZone: true
                        })
                      }}
                    </b-tooltip>
                  </div>
                  <div v-if="showReceivedDate && order.receivedDate" class="mb-1.5">
                    <p class="font-semibold">Received Date:</p>
                    <p id="order-received">
                      {{ order.receivedDate ? formatDate(order.receivedDate) : '' }}
                    </p>
                    <b-tooltip target="order-received" triggers="hover" v-if="order.receivedDate">
                      {{
                        formatDateTime(parseISO(order.receivedDate), {
                          format: 'dateTime',
                          timeZone: sessionUserTimeZone,
                          displayTimeZone: true
                        })
                      }}
                    </b-tooltip>
                  </div>
                  <div class="flex flex-column items-start gap-3 align-center mt-3">
                    <div v-if="source">Source: <Badge variant="info" :label="source" /></div>
                    <Badge v-if="order.merchant" variant="secondary" :label="merchant" />
                    <Badge variant="success" :label="formatOrderType(order.type)" />
                  </div>
                </div>
              </div>

              <!-- Customer -->
              <div class="w-full">
                <div class="flex gap-2 justify-between flex-col md:flex-row">
                  <div>
                    <a
                      :href="'/customer/?id=' + order.customerId"
                      :class="[
                        !order.customerId ? 'pointer-events-none cursor-default' : 'underline',
                        'text-slate-700 inline-flex gap-2'
                      ]"
                    >
                      <p class="text-lg font-bold mb-2">Customer</p>
                      <i class="fa-solid fa-sm fa-up-right-from-square" v-if="order.customerId"></i>
                    </a>
                    <CustomerInfo :customer="order" />
                    <p v-if="order.ipAddress">
                      <font-awesome-icon :icon="['far', 'desktop']" class="mr-1" />
                      {{ order.ipAddress }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- Device -->
              <div class="w-full" v-if="order.deviceSerialNumber && !order.subscriptionId">
                <p class="text-lg font-bold mb-2">Device</p>
                <div class="flex align-items-center gap-2 mb-1.5" v-if="order.deviceName">
                  <font-awesome-icon :icon="['fas', 'tablet-screen-button']" />
                  <p>{{ order.deviceName }}</p>
                </div>
                <div class="flex align-items-center gap-2" v-if="order.sellerName">
                  <font-awesome-icon :icon="['far', 'id-badge']" />
                  <p>{{ order.sellerName }}</p>
                </div>
              </div>

              <div class="w-full" v-if="order.subscriptionId">
                <p class="text-lg font-bold mb-2">Subscription</p>
                <div>
                  <p class="mb-2">
                    {{
                      formatCurrency(subscriptionAmount, {
                        precision: 2,
                        currency: currency
                      })
                    }}
                  </p>
                  <p v-if="subscriptionOrders.length">
                    Draws #{{ subscriptionOrders[0].drawNum }} - #{{
                      subscriptionOrders[subscriptionOrders.length - 1].drawNum
                    }}
                  </p>
                  <p v-if="subscriptionOrders.length" class="mt-2" id="order-datetime">
                    {{ formatDate(subscriptionOrders[0].eventDrawDate) }} -
                    {{ formatDate(subscriptionOrders[subscriptionOrders.length - 1].eventDrawDate) }}
                  </p>
                </div>
              </div>
              <!-- Additional Names -->
              <div class="w-full" v-if="order.secondaryName">
                <p class="text-lg font-bold mb-2">Additional Names</p>
                <p>{{ order.secondaryName }}</p>
              </div>

              <!-- Related Orders -->
              <div class="w-full" v-if="relatedOrders && relatedOrders.length > 0">
                <p class="text-lg font-bold mb-2">Related Orders</p>
                <div class="flex flex-col">
                  <a v-for="order in relatedOrders" :key="order.id" :href="'/order/' + order.id" class="underline">
                    Order #{{ formatUuid(order.id) }}
                  </a>
                </div>
              </div>

              <!-- Related Orders -->
              <div class="w-full" v-if="isMember">
                <div class="flex">
                  <p class="text-lg font-bold mb-2">Membership</p>
                  <span
                    v-b-tooltip.hover.top="
                      'This order was either generated by an order with source membership or the ticket buyer has opted-in to become a member with this order.'
                    "
                  >
                    <i class="fa-solid fa-circle-info pl-2"></i>
                  </span>
                </div>
                <Badge variant="info" label="Converted / Processed" />
              </div>
            </div>

            <!-- Purchase Summary -->
            <div class="flex flex-column gap-3 pt-3 pb-3">
              <div class="flex items-start sm:flex-row gap-2 align-center">
                <strong class="text-xl">Purchase Summary</strong>
              </div>
            </div>

            <!-- Cart Items -->
            <CreateOrderCart
              :cartItems="cartItems"
              :donationAmount="donationAmount"
              :donationRefunded="donationRefunded"
              readOnlyMode
              disabled
            />
          </div>

          <!-- Comments and Activity -->
          <div class="flex flex-col max-w-2xl w-full">
            <div class="flex flex-column gap-2">
              <strong class="text-xl">Comments & Activity</strong>

              <div class="border-b border-zinc-400">
                <nav class="-mb-px flex space-x-8 tabs w-full" aria-label="Tabs">
                  <a
                    v-for="tab in tabs"
                    :key="tab.name"
                    :href="tab.href"
                    @click="changeTabs(tab)"
                    :class="[
                      tab.current
                        ? 'border-green-800 text-green-800'
                        : 'border-transparent hover:border-green-700 hover:text-green-700',
                      'group inline-flex items-center border-b-2 py-2 px-1 font-medium no-underline text-slate-700'
                    ]"
                    :aria-current="tab.current ? 'page' : undefined"
                  >
                    <font-awesome-icon
                      :icon="['far', tab.icon]"
                      :class="[tab.current ? 'text-green-800' : '', '-ml-0.5 mr-2 h-5 w-5']"
                      aria-hidden="true"
                    />
                    <span>{{ tab.name }}</span>
                  </a>
                </nav>
              </div>
            </div>

            <!-- Tab Contents -->
            <div class="flex pt-6">
              <OrderCommentsList v-if="isCurrentTab('Comments')" class="flex flex-col w-full" :orderId="this.orderId" />
              <OrderHistoryList v-if="isCurrentTab('Activity')" class="flex flex-col w-full" :orderId="this.orderId" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import Alert from '@/components/ui/Alert.vue';
import config from '@/config';
import OrderServiceV2 from '@/lib/order-service-v2';
import CustomerInfo from '@/components/CustomerInfo';
import EmailOrderModal from '@/components/EmailOrderModal';
import Badge from '@/components/rbComponents/Badge.vue';
import formatOrderType, { isGoldrushPOSOrder } from '@/lib/format-order-type';
import { isStripePurchaseTimedOut, setStripePurchaseTimedOutError } from '@/lib/stripe-purchase-timeout';
import TicketServiceV2 from '@/lib/ticket-service-v2';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import { format, parseISO } from 'date-fns';
import RefundModalV2 from '@/components/RefundModalV2';
import OrderCommentsList from '@/components/OrderCommentsList.vue';
import DownloadFile from '../DownloadFile.vue';
import CreateOrderCart from '@/components/CreateOrderCart.vue';
import axios from 'axios';
import OrderHistoryList from '../OrderHistoryList.vue';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import ToggleOrderActivationModal from '@/components/ToggleOrderActivationModal.vue';
import EventServiceV2 from '@/lib/event-service-v2';
import FailOrderGroupModal from '@/components/modals/FailOrderGroupModal.vue';
import EditOrderModalV2 from '@/components/EditOrderModalV2';
import OrderStatusBadge from '@/components/OrderStatusBadge.vue';
import MNPSingleOrderPrinting from '@/components/reports/MNPSingleOrderPrinting.vue';

export default {
  props: ['orderId'],
  components: {
    CustomerInfo,
    EmailOrderModal,
    Alert,
    RefundModalV2,
    OrderCommentsList,
    DownloadFile,
    CreateOrderCart,
    OrderHistoryList,
    LoadingSpinner,
    Badge,
    BaseButton,
    ToggleOrderActivationModal,
    FailOrderGroupModal,
    EditOrderModalV2,
    OrderStatusBadge,
    MNPSingleOrderPrinting
  },
  data() {
    return {
      event: {},
      subscriptionAmount: null,
      loading: false,
      error: false,
      success: false,
      buttonLoading: false,
      fullPdfUrl: null,
      errorMessage: null,
      order: {},
      orderGroup: {},
      orderVoidedAt: null,
      orderCreatedAt: null,
      canEmailOrder: false,
      canDownloadTickets: false,
      canPrintOrder: false,
      canViewTickets: false,
      showEditOrderV2: false,
      relatedOrders: null,
      voidableOrder: false,
      voidReason: null,
      groupOrders: [],
      tabs: [
        { name: 'Comments', href: '#!', icon: 'comments', current: true }, // #! to stop scrolling to top of page on click - href might be removed entirely here TBD
        { name: 'Activity', href: '#!', icon: 'list-tree', current: false }
      ],
      cartItems: [],
      donationAmount: 0,
      donationRefunded: false,
      subscriptionOrders: [],
      showReceivedDate: false,
      canViewMNPOrderPrinting: false
    };
  },

  async mounted() {
    this.canEmailOrder = await unleashFeatureEnabled(UnleashKeys.EmailOrder);
    this.canDownloadTickets = await unleashFeatureEnabled(UnleashKeys.DownloadTickets);
    this.canPrintOrder = await unleashFeatureEnabled(UnleashKeys.PrintOrder);
    this.canViewTickets = await unleashFeatureEnabled(UnleashKeys.ViewTickets);
    this.showEditOrderV2 = await unleashFeatureEnabled(UnleashKeys.EditOrderV2);
    this.showReceivedDate = await unleashFeatureEnabled(UnleashKeys.MNPReceivedDate);
    this.canViewMNPOrderPrinting = await unleashFeatureEnabled(UnleashKeys.ViewMNPOrderPrinting);
    this.loading = true;
    await this.getOrderDetails();
    await this.getEventDetails();
    this.loading = false;
  },
  computed: {
    merchant() {
      switch (this.order?.merchant) {
        case 'stripe_connect':
          return 'Stripe Connect';
        case 'bambora':
          return 'Bambora';
        case 'stripe':
          return 'Stripe';
        case 'payfacto':
          return 'Payfacto';
        case 'global_payments':
          return 'Global Payments';
        default:
          return null;
      }
    },
    showTickets() {
      return this.order.type !== 'CUSTOM_ONLINE_PAYMENT_CREDIT';
    },
    currency() {
      return this.order?.currency?.toUpperCase();
    },
    canToggleOrderActivation() {
      return this.event.category !== 'Goldrush' && (this.event.isHomeLottery || this.event.parentEvent?.isHomeLottery);
    },
    isMember() {
      return this.groupOrders?.some((item) => item.isMember);
    },
    source() {
      return this.order?.info?.source?.toUpperCase();
    },
    isPendingOrder() {
      return this.orderGroup.orderStatus === 'PENDING';
    },
    isFailedOrder() {
      return this.orderGroup.orderStatus === 'FAILED';
    },
    failureError() {
      if (this.order.errorCode) {
        return `Error: ${this.order.errorCode} - ${this.order.errorMessage}`;
      } else if (this.order.errorMessage) {
        return `Error: ${this.order.errorMessage}`;
      } else {
        return null;
      }
    }
  },
  methods: {
    goToTicketPage() {
      window.location.href = `${config.RAFFLEBOX_URL}/order/${this.orderId}`;
    },
    changeTabs(tab) {
      this.tabs.forEach((tab) => {
        tab.current = false;
      });
      tab.current = true;
    },
    isCurrentTab(name) {
      return this.tabs.find((tab) => tab.name === name).current;
    },
    formatDate(date) {
      return format(parseISO(date), 'MMMM do, yyyy');
    },
    async getDownloadTicketsUrl() {
      this.buttonLoading = true;
      try {
        const params = {
          orderId: this.order.id
        };

        const response = await TicketServiceV2.getDownloadTicketsUrl(params);
        this.fullPdfUrl = response.url;
        this.buttonLoading = false;

        // Fetch the data from the URL and return it as a Blob using axios
        const responseBlob = await axios.get(response.url, { responseType: 'blob' });
        return responseBlob.data;
      } catch (error) {
        this.buttonLoading = false;
        this.errorMessage = this.parseError(error).message;
      }
    },
    formatOrderType: (val) => {
      return formatOrderType(val);
    },
    isGoldrushPOSOrder(value) {
      return isGoldrushPOSOrder(value);
    },
    async updateSuccess(message) {
      this.loading = true;
      this.success = message;

      await this.getOrderDetails();
      this.succcess = false;
      this.loading = false;
    },
    async getSubscriptionAmount(subscriptionId) {
      try {
        return await OrderServiceV2.getSubscriptionAmount(subscriptionId);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    async getSubscriptionOrders(subscriptionId) {
      try {
        return (await OrderServiceV2.listOrders({ subscriptionId, sortDir: 'asc', pageSize: 52, sortBy: 'drawNum' }))
          .data;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    async getEventDetails() {
      try {
        this.event = await EventServiceV2.retrieveEvent(this.order.eventId);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        this.loading = false;
      }
    },
    async getOrderDetails() {
      this.cartItems = [];

      try {
        const response = await OrderServiceV2.retrieveOrder(this.orderId);

        const order = response;
        if (isStripePurchaseTimedOut(order)) {
          setStripePurchaseTimedOutError(order);
        }

        this.order = order;

        this.orderVoidedAt = order.voidedAt
          ? 'Order was voided on ' + this.formatDateTime(this.parseISO(order.voidedAt))
          : 'Order was voided';
        this.subscriptionAmount = this.order.subscriptionId
          ? await this.getSubscriptionAmount(this.order.subscriptionId)
          : null;

        this.subscriptionOrders = this.order.subscriptionId
          ? await this.getSubscriptionOrders(this.order.subscriptionId)
          : [];

        if (this.order.subscriptionId) {
          if (this.cartItems.length === 0) {
            const event = {
              name: this.order.eventName,
              settings: {
                donationEnabled: false
              }
            };
            const { ticketSeries } = this.order.ticketPricing;
            const ticketPackages = ticketSeries.map((ticketPackage) => {
              return {
                quantity: ticketPackage.groups,
                numTickets: ticketPackage.numTickets,
                price: ticketPackage.priceCents / 100
              };
            });

            const cartItem = {
              event,
              ticketPackages: ticketPackages
            };

            this.cartItems.push(cartItem);
          }
        }

        const sessionUser = await getAuth().sessionUser();
        // Allow voidable as long as voidable equals true, and the person has the valid role.
        this.disableActivateOrderModal = sessionUser.hasRole('Customer Service');
        this.voidableOrder =
          this.order.voidOrder?.voidableV2 === true &&
          sessionUser.hasRole([Roles.RB_ADMIN, Roles.RAFFLE_ADMIN, Roles.GOLDRUSH_ADMIN, Roles.ACCOUNT_OWNER]);

        this.voidReason = this.order.voidOrder?.reasonV2;

        if (this.order.orderGroupId) {
          await this.getOrderGroup();
          await this.getRelatedOrders(this.order.orderGroupId);
        }
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        this.loading = false;
      }
    },
    async getRelatedOrders(orderGroupId) {
      const result = await OrderServiceV2.listOrders({
        orderGroupId: orderGroupId
      });

      // Remove the current order from the list of related orders
      const relatedOrders = result.data.filter((order) => order.id !== this.orderId);

      this.relatedOrders = relatedOrders;
    },
    async getOrderGroup() {
      const result = await OrderServiceV2.getOrderGroup(this.order.orderGroupId);
      this.orderGroup = result;

      if (result.createdBy) {
        this.order.createdBy = result.createdBy;

        this.order.createdBy.name = result.createdBy.name
          .split(' ')
          .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
          .join(' ');
      }

      if (result.cartItems) {
        this.groupOrders = result.cartItems.map((order) => {
          return {
            ...order,
            id: order.uuid
          };
        });
        for (const item of result.cartItems) {
          if (item.amountCents) {
            // Capture donation
            this.donationAmount = item.amountCents / 100;
            if (item.voidedAt) {
              this.donationRefunded = true;
            }
          } else {
            const { ticketSeries } = item.ticketPricing;
            const ticketPackages = ticketSeries.map((ticketPackage) => {
              return {
                quantity: ticketPackage.groups,
                numTickets: ticketPackage.numTickets,
                price: ticketPackage.price
              };
            });

            const cartItem = {
              event: item.event,
              ticketPackages: ticketPackages,
              voided: item.voidedAt ? true : false,
              orderId: item.uuid
            };
            this.cartItems.push(cartItem);
          }
        }

        // Add prop to cartItems to determine if donations are enabled for the order group
        this.cartItems.donationEnabled = result.cartItems.some((item) => item.event.settings.donationEnabled);
      }
    }
  }
};
</script>

<style scss scoped>
.order-details {
  a {
    color: #4f46e5;
  }

  .customer-info {
    margin-bottom: 0.375rem;
  }
}

.rb-link {
  padding: 0.25rem 0.75rem 0.25rem 0;
  color: #000000;
  border: 1px solid #626262;
  margin: 0 0.25rem 0.25rem 0;
}

.rb-link:hover {
  color: white;
  background-color: #626262;
  border-color: #626262;

  .rb-link-icon {
    color: white;
  }
}
.rb-link-icon {
  min-width: 2.5rem;
  color: #000000;
}

.no-void svg {
  fill: #dc3545;
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
