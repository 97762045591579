<template>
  <GenericModal ref="failOrderGroupModal" :error-message="modalErrorMessage">
    <template #openButton="{ openModal }">
      <div>
        <BaseButton variant="danger-outline" icon="ban" @click.native="openModal"> Fail Order Group </BaseButton>
      </div>
    </template>
    <template #header>
      <div class="text-lg rb-modal__title">Fail Order Group</div>
    </template>
    <template>
      <div class="p-4">
        <h6 class="font-semibold mb-2">
          Reason for failure
          <span class="text-danger">*</span>
        </h6>
        <b-form-group label-for="input-failure-reason" :invalid-feedback="veeErrors.first('input-failure-reason')">
          <b-form-textarea
            name="input-failure-reason"
            id="textarea"
            v-model="failureReason"
            placeholder="Enter reason..."
            :state="validateState('input-failure-reason')"
            :required="true"
            rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </template>
    <template #footer="{ closeModal }">
      <div class="flex gap-2 py-2">
        <BaseButton @click.native="closeModal" class="mr-2" aria-label="Close modal"> Close </BaseButton>
        <BaseButton @click.native="submit(closeModal)" :loading="submitting" variant="danger-outline">
          Fail Group
        </BaseButton>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import OrderServiceV2 from '@/lib/order-service-v2';

export default {
  props: {
    orderGroupId: {
      type: String
    }
  },
  components: {
    GenericModal,
    BaseButton
  },
  data() {
    return {
      submitting: false,
      failureReason: null,
      modalErrorMessage: null,
      successMessage: 'Order updated successfully'
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    async submit(closeModal) {
      this.loading = true;
      try {
        const updateData = {
          status: 'FAILED',
          errorMessage: this.failureReason
        };

        await OrderServiceV2.updateOrderGroup(this.orderGroupId, updateData);

        this.loading = false;
        closeModal();

        // Emit a success message
        this.$emit('success', this.successMessage);
      } catch (error) {
        this.modalErrorMessage = this.parseError(error).message;
        this.loading = false;
      } finally {
        this.canVoid = true;
      }
    }
  }
};
</script>
