<template>
  <GenericModal :title="title" size="sm" :config="modalConfig" @modal-closed="modalClosed" :errorMessage="errorMessage">
    <template #openButton="{ openModal }">
      <span id="toggle-activation-button">
        <BaseButton
          :iconFamily="buttonIcon.family"
          :icon="buttonIcon.icon"
          :variant="`${buttonColor}-outline`"
          @click.native="openModal"
          :disabled="disabled"
        >
          {{ actionVerbiage }} Order
        </BaseButton>
      </span>
      <b-tooltip target="toggle-activation-button" triggers="hover" v-if="disabled && reason">
        <span>{{ formatMessage(reason) }}</span>
      </b-tooltip>
    </template>
    <template #header>
      <div class="px-2 text-lg font-bold">{{ title }}</div>
    </template>
    <template>
      <div class="p-2">
        <div class="mb-4">
          <p v-html="description"></p>
          <Alert variant="red" class="mt-5" v-if="actionVerbiage === 'Deactivate'">
            <p class="font-bold text-sm">
              Deactivating an order will remove the tickets from the draw. The customer will not be refunded as part of
              this process.
            </p>
          </Alert>
        </div>
        <div>
          <!-- Single Order -->
          <ToggleOrderActivationDetails :orders="[...order]" :isSingleOrder="isSingleOrder" v-if="isSingleOrder" />

          <!-- Multiple Orders -->
          <ToggleOrderActivationDetails :orders="groupOrders" :isSingleOrder="isSingleOrder" v-if="isMultiOrder" />
        </div>
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <div class="flex py-1">
        <BaseButton variant="secondary" @click.native="closeModalHandler(closeModal)" class="mr-2"> Cancel </BaseButton>
        <BaseButton :variant="buttonColor" :loading="loading" @click.native="submit(closeModal)">
          {{ actionVerbiage }}
        </BaseButton>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import ToggleOrderActivationDetails from '@/components/ToggleOrderActivationDetails.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import OrderServiceV2 from '@/lib/order-service-v2';
import Alert from '@/components/ui/Alert.vue';

export default {
  props: {
    order: {
      type: Object
    },
    groupOrders: {
      type: Array
    },
    orderGroupId: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    GenericModal,
    ToggleOrderActivationDetails,
    BaseButton,
    Alert
  },
  computed: {
    actionVerbiage() {
      // If any order in the group is inactive or pending the whole group is inactive or pending
      const orderGroupStatus = this.groupOrders[0].status;
      const statuses = ['INACTIVE', 'PENDING'];

      if (statuses.includes(this.order.status) || statuses.includes(orderGroupStatus)) {
        return 'Activate';
      } else {
        return 'Deactivate';
      }
    },
    buttonColor() {
      if (this.actionVerbiage === 'Activate') {
        return 'success';
      } else {
        return 'danger';
      }
    },
    buttonIcon() {
      if (this.actionVerbiage === 'Activate') {
        return {
          family: 'fas',
          icon: 'check-circle'
        };
      } else {
        return {
          family: 'far',
          icon: 'ban'
        };
      }
    },
    title() {
      if (this.isMultiOrder) {
        return `${this.actionVerbiage} Orders`;
      } else {
        return `${this.actionVerbiage} Order`;
      }
    },
    description() {
      if (this.isSingleOrder) {
        return `Are you sure you wish to ${this.actionVerbiage} this order?`;
      } else {
        return `This order is part of a group. ${this.actionVerbiage.slice(0, -1)}ing
          this order will also ${this.actionVerbiage.toLowerCase()} all other orders in this group.`;
      }
    },
    successfulMessage() {
      if (this.isMultiOrder) {
        return `Order(s) ${this.actionVerbiage}d successfully`;
      } else {
        return `Order ${this.actionVerbiage}d successfully`;
      }
    },
    totalAmount() {
      return this.order.ticketPricing?.totalPriceCents / 100;
    },
    currency() {
      return this.order.currency.toUpperCase();
    },
    isMultiOrder() {
      return this.groupOrders ? this.groupOrders.length > 1 : false;
    },
    isSingleOrder() {
      return !this.isMultiOrder;
    }
  },
  data() {
    return {
      modalConfig: {
        showHeaderClose: true,
        showCloseModalButton: false
      },
      errorMessage: null,
      loading: false
    };
  },
  methods: {
    formatMessage: (message) => {
      let newMsg = message;
      if (typeof message === 'string') {
        newMsg = newMsg.replace(/-/g, ' ');
        newMsg = newMsg.charAt(0).toUpperCase() + newMsg.slice(1);
      }

      return newMsg;
    },
    async submit(closeModal) {
      this.loading = true;
      try {
        if (this.actionVerbiage === 'Deactivate') {
          await OrderServiceV2.deactivateOrderGroup(this.orderGroupId);
        } else {
          if (this.order.status === 'PENDING') {
            await OrderServiceV2.updateOrderGroup(this.orderGroupId, { status: 'ACTIVE' });
          } else {
            await OrderServiceV2.activateOrderGroup(this.orderGroupId);
          }
        }

        this.loading = false;
        closeModal();

        // Emit a success message
        this.$emit('success', this.successfulMessage);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        this.loading = false;
      }
    },
    closeModalHandler(closeModal) {
      closeModal();
    },
    modalClosed() {
      this.errorMessage = null;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>
