import Axios from '@/axios';

const jobsUrl = '/job-export-service/v2/export-jobs';

const createExportJob = async ({ organizationId, reportName, filters }) => {
  const body = {
    organizationId,
    reportName,
    filters
  };

  const response = await Axios.post(jobsUrl, body);
  return response.data.data;
};

const listExportJobs = async (params = {}) => {
  const response = await Axios.get(jobsUrl, {
    params
  });

  return response.data.data;
};

const downloadExportCsv = async (body) => {
  const response = await Axios.post(`${jobsUrl}/pre-signed-url`, body);
  return response.data.data;
};

const updateExportJob = async (jobId, body) => {
  const response = await Axios.patch(`${jobsUrl}/${jobId}`, body);
  return response.data.data;
};

export default {
  listExportJobs,
  downloadExportCsv,
  createExportJob,
  updateExportJob
};
